@font-face {
    font-family: 'Karasha';
    src: url('./Karasha.otf') format('opentype');
}

.kimono-input {
    width: 100%;
    padding: 20px 10px ;
    margin: 10px 0;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    font-size: 16px;
    transition: border-color .3s;
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    -webkit-appearance: none;
    transition: all .3s;
}

.kimono-input:focus {
    outline: none;
    -webkit-box-shadow: 1px 2px 19px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 19px 0px rgba(0, 0, 0, 0.2);
}

.kimono-button {
    display: inline-block;
    width: 100%;
    padding: 20px 10px ;
    margin: 10px 0;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    font-size: 16px;
    transition: border-color .3s;
    text-transform: uppercase;
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    background-color: rgb(239, 239, 239);
    height: auto;
    -webkit-appearance: none;
    transition: all .3s;
    text-decoration: none;
    text-align: center;
}

.kimono-button:disabled {
    background-color: #aaaaaa !important;
    color: rgb(0, 0, 0) !important;
    cursor: not-allowed;
}

.kimono-button.reverse {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: underline;
    background-color: transparent;
}

.kimono-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.kimono-buttons .kimono-button {
    width: auto;
    margin: 10px 10px;
}

.kimono-button.loading {
    cursor: progress;
}

.kimono-button.reverse:hover {
    cursor: pointer;
}

.kimono-button:not(.reverse):hover {
    -webkit-box-shadow: 1px 2px 19px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 19px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.kimono-hello {
    margin: 100px 20px;
    -webkit-appearance: none;
}

.kimono-image {
    width: 80%;
    max-width: 300px;
    margin: auto;
    -webkit-appearance: none;
}

.kimono-image img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.kimono-box {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    margin: 20px;
    -webkit-appearance: none;
    text-decoration: none;
    transition: all .3s;
    color: black;
}

.kimono-box.reverse {
    transform: rotateY(180deg);
}

.kimono-box.no-margin {
    margin: 0;
    width: 100%;
}

.kimono-box.reverse .kimono-box-container {
    display: none;
}

.kimono-box:not(.reverse) .kimono-box-reverse-container {
    display: none;
}

.kimono-box-container, .kimono-box-reverse-container {
    width: calc(100% - 40px);
    padding: 20px;
    background-image: url('./cloudsw.png');
}

.kimono-box.reverse .kimono-box-reverse-container {
    transform: rotateY(180deg);
}

.kimono-box:hover {
    -webkit-box-shadow: 1px 2px 19px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 19px 0px rgba(0, 0, 0, 0.2);
}

.kimono-box-title {
    display: flex;
}

.kimono-box-title * {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
}

.kimono-box-title h3 {
    text-decoration: underline;
}

.kimono-box-content {
    font-size: 1.2rem;
    margin: 0;
}

.kimono-box-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
}

.kimono-box-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    background: white;
    color: black;
    border-radius: 0px 0px 15px 15px;
}

.reverse .kimono-box-footer {
    transform: rotateY(180deg);
}

.kimono-joke {
    width: 80%;
    margin: 100px auto;
}

.kimono-joke h1.citation {
    margin: 50px 0;
}

.kimono-joke h1.citation::after {
    content: "J.F. Tassin";
    font-size: 1rem;
    opacity: 0.5;
    position: absolute;
    transform: translate(-60%, 2rem)
}

.kimono-center {
    margin: 50px auto;
}

.kimono-center.tiny {
    margin: 10px auto;
}

.kimono-loading {
    display: flex;
    justify-content: center;
}

.kimono-loading .kimono-svg {
    width: 100%;
}

.kimono-loading .kimono-svg text{
    text-transform: uppercase;
    animation: animateSVG 3s infinite alternate;
    stroke-width: 2;
    font-size: 40px;
    font-family: Karasha;
    fill: transparent;
}

.kimono-collapsible {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin: 20px 20px;
    text-decoration: none;
    user-select: none;
    align-items: center;
}

.kimono-collapsible-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #647191;
    color: white;
    padding: 10px 20px;
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    width: 100%;
}

.kimono-collapsible-content {
    overflow: hidden;
    max-height: 500px;
    transition: max-height .5s ease-out;
    width: calc(100% - 40px);
}

.kimono-collapsible.collapsed .kimono-collapsible-content {
    max-height: 0px;
}

@keyframes animateSVG {
    0% {
        fill: transparent;
        stroke: var(--danger);
        stroke-dashoffset: 25%;
        stroke-dasharray: 0 50%;
        stroke-width: 1;
    }
    70% {
        fill: transparent;
        stroke: var(--danger);
    }
    80% {
        fill: transparent;
        stroke: var(--primary);
        stroke-width: 2;
    }
    100% {
        fill: var(--primary);
        stroke: var(--primary);
        stroke-dashoffset: -25%;
        stroke-dasharray: 50% 0;
        stroke-width: 0;
    }
  }

.kimono-switch-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
}

.kimono-switch-container :first-child {
    max-width: calc(100% - 60px);
}

.kimono-switch {
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 1em 0;
}

.kimono-switch input {
    display: none;
}

.kimono-switch .slider {
    position: absolute;
    cursor: pointer;
    width: 60px;
    height: 34px;
    background-color: #ccc;
    transition: .4s;
}

.kimono-switch .slider::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

.kimono-switch input:checked + .slider {
    background-color: var(--primary);
}

.kimono-switch input:focus + .slider {
    box-shadow: 0 0 1px var(--primary);
}

.kimono-switch input:checked + .slider::before {
    transform: translateX(26px);
}

.kimono-switch .slider.round {
    border-radius: 34px;
}

.kimono-switch .slider.round::before {
    border-radius: 50%;
}

.kimono-select {
    width: 100%;
    background-color: white;
    padding: 20px 10px;
    margin: 10px 0;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    font-size: 16px;
    transition: border-color .3s;
    box-shadow: 1px 2px 9px 0px rgb(0 0 0 / 20%);
    font-weight: bold;
    -webkit-appearance: none;
}

.kimono-select:focus {
    outline: none;
}

.kimono-modal  {
    display: block;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    max-height: 50vh;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 17px;
    z-index: 9999;
    box-shadow: 1px 2px 19px 0px rgb(0 0 0 / 20%);
    backdrop-filter: blur(5px);
    animation: appear-opposite .5s cubic-bezier(0.44, 0.26, 0.6, 0.74);
    margin: auto;
    -webkit-appearance: none;
}

.kimono-modal.danger {
    background-color: var(--danger-lite);
    color: white;
}

.kimono-modal.success {
    background-color: #4b8f8c7f;
    color: white;
}

.kimono-modal.warning {
    background-color: var(--warning-lite);
    color: white;
}

.kimono-modal.info {
    background-color: var(--primary-lite);
    color: white;
}

.kimono-modal.hidden {
    animation: disappear .5s cubic-bezier(1, 1, 0, 1) forwards;
}

@keyframes disappear {
    0% {
        transform: translateY(0) translateX(-50%);
    }
    99% {
        transform: translateY(-150%) translateX(-50%);
    }
    100% {
        display: none;
        transform: translateY(-150%) translateX(-50%);
    }
}

@keyframes appear-opposite {
    0% {
        transform: translateY(-150%) translateX(-50%);
    }
    100% {
        transform: translateY(0) translateX(-50%);
    }
}

@media screen and (max-width: 768px) {
    @keyframes disappear {
        0% {
            transform: translateX(0);
        }
        99% {
            transform: translateX(-150%);
        }
        100% {
            display: none;
            transform: translateX(-150%);
        }
    }
    
    @keyframes appear-opposite {
        0% {
            transform: translateX(150%);
        }
        100% {
            transform: translateX(0);
        }
    }

    .kimono-modal {
        margin: 10px;
        width: calc(100% - 40px);
        left: 0;
        top: 0px;
        transform: none;
        padding: 10px;
    }

    .kimono-buttons {
        flex-direction: column;
    }

    .kimono-buttons .kimono-button {
        width: 100%;
        margin: 5px 0;
    }

    .kimono-hello {
        margin: 40px 20px;
        -webkit-appearance: none;
    }
}