body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --danger: #df7373;
  --success: #4b8f8c;
  --primary: #647191;
  --warning: #efbd49;
  --danger-lite: #df73737F;
  --success-lite: #4b8f8c7f;
  --primary-lite: #6471917f;
  --warning-lite: #efbd497f;
}

.kimono-bounce {
  display: inline-block;
  animation: bounce 10s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.kimono-bounce.two {
  animation-delay: 3s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  3% {
    transform: translateY(-10px);
  }
  5% {
    transform: translateY(0);
  }
  7% {
    transform: translateY(0);
  }
  9% {
    transform: translateY(-10px);
  }
  11% {
    transform: translateY(0);
  }
  13% {
    transform: translateY(-10px);
  }
  15% {
    transform: translateY(0);
  }
}

.turn-slow {
  animation: turn-slow 10s linear infinite;
}

.disappear-turn-slow {
  animation: disappear-turn-slow 5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

@keyframes turn-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes disappear-turn-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    width: 0px;
  }
}

.danger {
  color: var(--danger);
}

.danger-bg {
  background-color: var(--danger-lite) !important;
  color: white !important;
}

.success {
  color: var(--success) !important;
}

.success-bg {
  background-color: var(--success-lite) !important;
  color: white !important;
}

.primary {
  color: var(--primary) !important;
}

.primary-bg {
  background-color: var(--primary-lite) !important;
  color: white !important;
}

.warning {
  color: var(--warning) !important;
}

.warning-bg {
  background-color: var(--warning-lite) !important;
  color: white !important;
}

.transparent-bg {
  background-color: #ffffff7f !important;
  color: black !important;
}

/*.danger-bg, .primary-bg, .warning-bg, .success-bg, .transparent-bg {
  backdrop-filter: blur(5px);
}*/

.danger-bg:visited, .primary-bg:visited, .warning-bg:visited, .success-bg:visited {
  color: white !important;
}
