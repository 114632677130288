.navbar-container {
    display: flex;
    margin: 20px 0 50px 0;
    justify-content: center;
}

.navbar-back {
    background-color: rgba(255, 255, 255, .5);
    backdrop-filter: blur(10px);
    padding: 0px;
    border-radius: 22px;
    cursor: pointer;
    width: 32px;
    margin-right: -64px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    justify-content: center;
}

.navbar-back span {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
}

.navbar-back.active {
    animation: margin .5s forwards;
}

.navbar-back.reverse {
    animation: margin-reverse .5s forwards;
}

@keyframes margin {
    0% {
        margin-right: -64px;
    }
    100% {
        margin-right: 20px;
        -webkit-box-shadow: 7px 7px 120px 10px rgba(0, 0, 0, .2);
        box-shadow: 7px 7px 120px 10px rgba(0, 0, 0, .2);
    }
}

@keyframes margin-reverse {
    0% {
        margin-right: 20px;
        -webkit-box-shadow: 7px 7px 120px 10px rgba(0, 0, 0, .2);
        box-shadow: 7px 7px 120px 10px rgba(0, 0, 0, .2);
    }
    100% {
        margin-right: -64px;
    }
    
}

.navbar-items {
    list-style: none;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    background-color: rgba(255, 255, 255, .5);
    padding: 16px;
    border-radius: 22px;
    -webkit-box-shadow: 7px 7px 120px 10px rgba(0, 0, 0, .2);
    box-shadow: 7px 7px 120px 10px rgba(0, 0, 0, .2);
    backdrop-filter: blur(10px);
    transition: width .5s;
    min-width: 700px;
}

.navbar-item {
    padding: 10px 16px;
}

.navbar-item a {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
}

.navbar-item a::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: inherit;
    transition: width .3s ease-in-out;
}

.navbar-item a:hover::after {
    width: 100%;
}

.navbar-item:nth-child(1) a {
    color: #000000;
}

.navbar-item:nth-child(1) a::after {
    background-color: #000000;
}

.navbar-item:nth-child(2) a {
    color: #4b8f8c;
}

.navbar-item:nth-child(2) a::after {
    background-color: #4b8f8c;
}

.navbar-item:nth-child(3) a {
    color: #df7373;
}

.navbar-item:nth-child(3) a::after {
    background-color: #df7373;
}

.navbar-item:nth-child(4) a {
    color: #647191;
}

.navbar-item:nth-child(4) a::after {
    background-color: #647191;
}

@media screen and (max-width: 768px) {
    .navbar-items {
        min-width: 0px;
    }

    .navbar-item a {
        font-size: 1rem;
    }

    .navbar-back {
        padding: 16px;
    }

    .navbar-back span {
        font-size: 1rem;
    }

    .navbar-item a span:nth-child(1) {
        display: block;
    }

    .navbar-item a span:nth-child(2) {
        display: none;
    }

    .navbar-item.active a span:nth-child(1), .navbar-item.active a span:nth-child(2) {
        display: inline-block;
    }

    .navbar-item {
        padding: 8px 16px;
    }
}