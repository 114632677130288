.shortcut-container {
    position: fixed;
    bottom: 10px;
    right: 10px;    
    animation: shortcut-appear .5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

.shortcut-container.hidden {
    animation: shortcut-disappear .5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

@keyframes shortcut-appear {
    0% {
        bottom: -100px;
    }
    100% {
        bottom: 10px;
    }
}

@keyframes shortcut-disappear {
    0% {
        bottom: 10px;
    }
    100% {
        bottom: -100px;
    }
}