.app-container {
  width: calc(100% - 20px);
  max-width: calc(700px - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 0 auto;
}

.app-content {
  width: 100%;
}